<template>
    <div class="container">
        <div class="header-part flex">
            <div class="tab-part flex">
                <el-button
                    type="primary"
                    class="tab-item flex-both-center"
                    size="mini"
                    v-for="(item, index) in tabPart.list"
                    :key="index"
                    @click="getTabItem(item, index)"
                    :style="{ color: index == tabPart.activeNum ? '#000' : '' }"
                    >{{ item.name }}</el-button
                >
            </div>
        </div>

        <div class="video-part scrollbar flex-between flex-wrap">
            <flv v-if="tabPart.activeNum == 0" :listAry="listAry"></flv>
            <liveplayer
                v-if="tabPart.activeNum == 1"
                :listAry="listAry"
            ></liveplayer>
            <tcplayer
                v-if="tabPart.activeNum == 2"
                :listAry="listAry"
            ></tcplayer>
            <manufactor
                v-if="tabPart.activeNum == 3"
                :listAry="listAry"
            ></manufactor>

            <custom v-if="tabPart.activeNum == 4"></custom>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import flv from "./flv";
import liveplayer from "./liveplayer";
import tcplayer from "./tcplayer";
import manufactor from "./manufactor";
import custom from "./custom";

export default {
    components: {
        flv,
        liveplayer,
        tcplayer,
        manufactor,
        custom,
    },
    name: "",
    data() {
        return {
            tabPart: {
                activeNum: 0,
                list: [
                    {
                        name: "flv",
                    },
                    {
                        name: "liveplayer",
                    },
                    {
                        name: "tcplayer",
                    },
                    {
                        name: "厂家",
                    },
                    {
                        name: "自定义",
                    },
                ],
            },
            listAry: [],
        };
    },
    created() {},
    mounted() {
        this.getSxtList();
    },
    methods: {
        ...mapActions(["MapCameraList", "getVectorStyle"]),
        getTabItem(item, index) {
            this.tabPart.activeNum = index;
        },
        // 摄像机列表
        async getSxtList() {
            // http://192.168.23.235:53372/live/ch12_0_1.flv;
            // http://192.168.23.235:80/live/ch2_0_1.m3u8
            // https://jmsjc.jmsclwh.cn/flv/live/ch2_0_1.flv
            // https://jmsjc.jmsclwh.cn/live/ch2_0_1.m3u8
            let result = await this.MapCameraList({});
            this.listAry = result;
        },
        // getUrl(item) {
        //     switch (window.location.hostname) {
        //         // 监测中心 外网
        //         case "jmsjc.jmsclwh.cn":
        //             item.flvUrl = `https://jmsjc.jmsclwh.cn/flv/live/${item.PD}.flv`;
        //             item.m3u8Url = `https://jmsjc.jmsclwh.cn/live/${item.PD}.m3u8`;
        //             break;
        //         // 监测中心 内网
        //         case "192.168.23.236":
        //         // 公司
        //         case "jmsgcl.geo-compass.com":
        //         //开发环境
        //         default:
        //             item.flvUrl = `http://192.168.23.235:53372/live/${item.PD}.flv`;
        //             item.m3u8Url = `http://192.168.23.235:80/live/${item.PD}.m3u8`;
        //             break;
        //     }
        //     return item;
        // },
    },
};
</script>
<style scoped lang="scss">
.container {
    width: 100%;
    height: 100%;
    .header-part {
        width: 100%;
        height: 30px;
        .tab-part {
            height: 100%;
            margin-right: 20px;
            .tab-item {
                height: 100%;
                margin-right: 10px;
                cursor: pointer;
            }
        }

        .custom-part {
            margin: 0 10px;
            height: 20px;
        }
    }

    .video-part {
        width: 100%;
        height: calc(100% - 60px);
        margin-top: 30px;
    }
}
</style>
