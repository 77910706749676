<template>
    <div class="container-ctn">
        <el-tag>返回值 RTMP 存在的设备</el-tag>
        <div class="audio-part flex">
            <div
                v-for="(item, index) in typeList"
                :key="index"
                class="audio-item flex-both-center"
                @click="changeAudio(item, index)"
                :style="{ color: type == index ? 'red' : '' }"
            >
                {{ item.name }}
            </div>
        </div>
        <div class="flex-between flex-wrap" v-if="type == 0">
            <div
                class="videoItem"
                v-for="(item, index) in innerList"
                :key="index"
            >
                <div
                    :id="`vidoeBox_${index}`"
                    style="width: 394px; height: 220px; border: 1px solid #000"
                ></div>
                <div class="">
                    <div>{{ item.SBMC }}</div>
                    <div>{{ item.PD }}</div>
                    <div>{{ item.url }}</div>
                </div>
            </div>
        </div>
        <div class="video-part flex-y-center" v-else>
            <div class="video-list scrollbar">
                <div
                    class="video-item flex-between"
                    :class="[activeNum == index ? 'video-active' : '']"
                    v-for="(item, index) in innerList"
                    :key="index"
                    @click="getDetail(item, index)"
                >
                    <div class="name">
                        {{ item.SBMC }}
                    </div>
                    <div v-if="item.PD">
                        <span>PD</span>
                        <span class="name"> {{ item.PD }}</span>
                    </div>
                    <div v-if="item.RTMP">
                        <span>RTMP</span>
                        <span class="name"> {{ item.RTMP }}</span>
                    </div>
                </div>
            </div>
            <div class="flex-1">
                <div class="flex">
                    <div>
                        <div
                            id="vidoeBox"
                            style="
                                width: 394px;
                                height: 220px;
                                border: 1px solid #000;
                            "
                        ></div>
                    </div>
                    <div class="time-part">
                        <div class="flex">
                            <div class="label">视频地址</div>
                            <div>{{ address }}</div>
                        </div>
                        <div class="flex">
                            <div class="label">开始时间</div>
                            <div>{{ startTime }}</div>
                        </div>
                        <div class="flex">
                            <div class="label">结束时间</div>
                            <div>{{ endTime }}</div>
                        </div>
                        <div class="flex">
                            <div class="label">加载时间</div>
                            <div>
                                <span>{{ longTime }}</span>
                                <span v-if="longTime">毫秒</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { TcPlayer } from "tcplayer";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {
        TcPlayer,
    },
    name: "",
    data() {
        return {
            innerList: [],
            poster: require("@image/bigScreen/icon/sxtPoster.gif"),
            type: 1,
            typeList: [
                {
                    name: "所有监控状态",
                },
                {
                    name: "某个监控加载时长",
                },
            ],
            activeNum: null,
            player: null,
            address: "",
            startTime: "",
            endTime: "",
            longTime: "",
            num: 0,
        };
    },
    props: {
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    watch: {
        listAry: {
            immediate: true,
            handler() {
                this.innerList = [];
                this.listAry.map((item) => {
                    if (item.RTMP) {
                        item = this.getUrl(item);
                        this.innerList.push(item);
                    }
                });
                console.log(
                    "%c 🍔 this.listAry: ",
                    "font-size:20px;background-color: #33A5FF;color:#fff;",
                    this.innerList
                );
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions([""]),
        changeAudio(item, index) {
            this.type = index;
            if (index == 0) {
                this.$nextTick(() => {
                    this.innerList.map((item, index) => {
                        item.player = new TcPlayer(`vidoeBox_${index}`, {
                            m3u8: item.url,
                            autoplay: true,
                            live: true,
                            width: "394",
                            height: "220",
                            flash: false,
                            muted: true,
                            poster: this.poster,
                        });
                        item.player.play();
                    });
                });
            } else {
            }
        },
        getUrl(item) {
            switch (window.location.hostname) {
                // 监测中心 外网
                case "jmsjc.jmsclwh.cn":
                    item.url = `https://jmsjc.jmsclwh.cn/live/${item.PD}.m3u8`;
                    break;
                // 监测中心 内网
                case "192.168.23.236":
                // 公司
                case "jmsgcl.geo-compass.com":
                //开发环境
                default:
                    item.url = `http://192.168.23.235:80/live/${item.PD}.m3u8`;
                    break;
            }
            return item;
        },
        getDetail(item, index) {
            this.activeNum = index;
            this.num = 0;
            this.address = item.url;
            this.endTime = "";
            this.longTime = "";
            this.startTime = this.common.getTimeYmdhms();
            if (this.player) {
                this.player.destroy();
            }
            this.player = new TcPlayer(`vidoeBox`, {
                m3u8: item.url,
                autoplay: true,
                live: true,
                width: "400",
                height: "300",
                flash: false,
                muted: true,
                poster: this.poster,
            });
            this.player.play();
            this.endTime = this.common.getTimeYmdhms();
            this.longTime =
                new Date(this.endTime).getTime() -
                new Date(this.startTime).getTime();
        },
    },
    destroyed() {
        // this.innerList.map((item) => {
        //     item.player.destroy();
        // });
    },
};
</script>
<style scoped lang="scss">
.container-ctn {
    .audio-part {
        width: 100%;
        margin-top: 10px;
        .audio-item {
            width: 100px;
            height: 40px;
            background-color: #ecf5ff;
            height: 32px;
            padding: 0 10px;
            line-height: 30px;
            font-size: 12px;
            color: #409eff;
            border-width: 1px;
            border-style: solid;
            border-radius: 4px;
            box-sizing: border-box;
            white-space: nowrap;
            cursor: pointer;
            margin-right: 10px;
        }
    }
    .video-part {
        width: 100%;
        margin-top: 10px;
        .video-list {
            width: 700px;
            height: 800px;
            margin-right: 10px;
            .video-item {
                margin-right: 10px;
                margin-bottom: 20px;
                position: relative;
                cursor: pointer;
                .name {
                    margin-right: 10px;
                    background-color: #ecf5ff;
                    display: inline-block;
                    height: 32px;
                    padding: 0 10px;
                    line-height: 30px;
                    font-size: 12px;
                    color: #409eff;
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 4px;
                    box-sizing: border-box;
                    white-space: nowrap;
                }
            }
            .video-active {
                .name {
                    color: red;
                }
            }
        }
        .time-part {
            margin-left: 10px;
            .label {
                margin-right: 10px;
            }
        }
    }
    .videoItem {
        width: 394px;
        height: 320px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
</style>