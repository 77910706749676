<template>
    <div class="container-ctn">
        <div class="audio-part flex">
            <div
                v-for="(item, index) in typeList"
                :key="index"
                class="audio-item flex-both-center"
                @click="changeAudio(item, index)"
                :style="{ color: type == index ? 'red' : '' }"
            >
                {{ item.name }}
            </div>
        </div>
        <div class="video-part flex-y-center">
            <div class="video-list scrollbar">
                <div
                    class="video-item flex-between"
                    :class="[activeNum == index ? 'video-active' : '']"
                    v-for="(item, index) in listInner"
                    :key="index"
                    @click="getDetail(item, index)"
                >
                    <div class="name">
                        {{ item.SBMC }}
                    </div>
                    <div v-if="item.PD">
                        <span>PD</span>
                        <span class="name"> {{ item.PD }}</span>
                    </div>
                    <div v-if="item.RTMP">
                        <span>RTMP</span>
                        <span class="name"> {{ item.RTMP }}</span>
                    </div>
                </div>
            </div>
            <div class="flex-1">
                <div class="flex">
                    <div style="width: 394px; height: 500px">
                        <live-player
                            :video-url="address"
                            live="true"
                            style="width: 394px; height: 500px"
                            :poster="poster"
                            @timeupdate="timeupdate"
                        ></live-player>
                    </div>
                    <div class="time-part">
                        <div class="flex">
                            <div class="label">视频地址</div>
                            <div>{{ address }}</div>
                        </div>
                        <div class="flex">
                            <div class="label">开始时间</div>
                            <div>{{ startTime }}</div>
                        </div>
                        <div class="flex">
                            <div class="label">结束时间</div>
                            <div>{{ endTime }}</div>
                        </div>
                        <div class="flex">
                            <div class="label">加载时间</div>
                            <div>
                                <span>{{ longTime }}</span>
                                <span v-if="longTime">毫秒</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {},
    name: "",
    data() {
        return {
            listInner: [],
            poster: require("@image/bigScreen/icon/sxtPoster.gif"),
            type: 0,
            activeNum: null,
            typeList: [
                {
                    name: "球机",
                    list: [],
                },
                {
                    name: "枪机",
                    list: [],
                },
            ],
            player: null,
            address: "",
            startTime: "",
            endTime: "",
            longTime: "",
            num: 0,
        };
    },
    props: {
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    watch: {
        listAry: {
            immediate: true,
            handler() {
                if (this.listAry.length) {
                    this.typeList[0].list = [];
                    this.typeList[1].list = [];
                    this.listAry.map((item) => {
                        if (item.SBQTLX == 0) {
                            this.typeList[0].list.push(item);
                        } else {
                            this.typeList[1].list.push(item);
                        }
                    });
                    this.listInner = this.typeList[0].list;
                }
            },
        },
    },
    created() {},
    mounted() {},
    methods: {
        ...mapActions([""]),
        changeAudio(item, index) {
            this.type = index;
            this.listInner = item.list;
        },
        getDetail(item, index) {
            this.startTime = "";
            this.address = "";
            this.endTime = "";
            this.longTime = "";
            this.activeNum = index;
            this.getUrl(item).then((url) => {
                this.num = 0;
                this.startTime = this.common.getTimeYmdhms();
                this.address = url;
            });
        },
        async getUrl(item) {
            let url = "";
            if (item.PD) {
                switch (window.location.hostname) {
                    // 监测中心 外网
                    // 公司
                    case "jmsgcl.geo-compass.com":
                        url = `http://jmsjc.jmsclwh.cn/flv/live/${item.PD}.flv`;
                        // url = `http://jmsjc.jmsclwh.cn/live/${item.PD}.m3u8`;
                        break;
                    case "jmsjc.jmsclwh.cn":
                        url = `https://jmsjc.jmsclwh.cn/flv/live/${item.PD}.flv`;
                        // url = `https://jmsjc.jmsclwh.cn/live/${item.PD}.m3u8`;
                        break;
                    // 监测中心 内网
                    case "192.168.23.236":
                    //开发环境
                    default:
                        url = `http://192.168.23.235:53372/live/${item.PD}.flv`;
                        // url = `http://192.168.23.235:80/live/${item.PD}.m3u8`;
                        break;
                }
            } else {
                url = await this.getUrlFlv(item);
            }
            return url;
        },
        async getUrlFlv(item) {
            console.log(
                "%c 🦐 item: ",
                "font-size:20px;background-color: #4b4b4b;color:#fff;",
                item
            );
            let result = await this.common.getFlvUrl(item);
            let url = "";
            if (result.Data && result.Data.outUrl) {
                let str = result.Data.outUrl;
                url = `https://${str.slice(7)}`;
            } else {
                this.common.showMsg(`${item.SBMC}设备故障`, "warning");
            }
            return url;
        },
        timeupdate(msg) {
            if (!this.num) {
                this.num++;
                this.endTime = this.common.getTimeYmdhms();
                this.longTime =
                    new Date(this.endTime).getTime() -
                    new Date(this.startTime).getTime();
            }
        },
    },
    destroyed() {},
};
</script>
<style scoped lang="scss">
.container-ctn {
    width: 100%;
    height: 100%;
    .audio-part {
        width: 100%;
        .audio-item {
            width: 100px;
            height: 40px;
            background-color: #ecf5ff;
            height: 32px;
            padding: 0 10px;
            line-height: 30px;
            font-size: 12px;
            color: #409eff;
            border-width: 1px;
            border-style: solid;
            border-radius: 4px;
            box-sizing: border-box;
            white-space: nowrap;
            cursor: pointer;
            margin-right: 10px;
        }
    }
    .video-part {
        width: 100%;
        margin-top: 10px;
        .video-list {
            width: 700px;
            height: 800px;
            margin-right: 10px;
            .video-item {
                margin-right: 10px;
                margin-bottom: 20px;
                position: relative;
                cursor: pointer;
                .name {
                    margin-right: 10px;
                    background-color: #ecf5ff;
                    display: inline-block;
                    height: 32px;
                    padding: 0 10px;
                    line-height: 30px;
                    font-size: 12px;
                    color: #409eff;
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 4px;
                    box-sizing: border-box;
                    white-space: nowrap;
                }
            }
            .video-active {
                .name {
                    color: red;
                }
            }
        }
        .time-part {
            margin-left: 10px;
            .label {
                margin-right: 10px;
            }
        }
    }
}
</style>