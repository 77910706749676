<template>
    <div class="container-ctn">
        <div class="custom-part flex">
            <el-input
                v-model="customUrl"
                placeholder="请输入自定义地址"
                clearable
                style="width: 400px; height: 20px; margin-right: 10px"
            ></el-input>
            <el-button
                type="primary"
                class="tab-item flex-both-center"
                size="mini"
                @click="playVideo()"
                >确认</el-button
            >
        </div>

        <div class="audio-part flex">
            <div
                v-for="(item, index) in typeList"
                :key="index"
                class="audio-item flex-both-center"
                @click="changeAudio(item)"
                :style="{ color: type == item.name ? 'red' : '' }"
            >
                {{ item.name }}
            </div>
        </div>

        <div class="type-part flex-between">
            <div
                v-for="(item, index) in listList"
                :key="index"
                class="type-item"
            >
                <el-tag class="name">{{ item.name }} </el-tag>

                <div class="flex-between">
                    <el-tag>启动时间:{{ item.startTime }} </el-tag>
                    <el-tag>开播时间: {{ item.endTime }}</el-tag>
                    <el-tag>启动耗时: {{ item.longTime }}毫秒</el-tag>
                </div>
            </div>
        </div>
        <div class="flex-between">
            <video
                id="vidoeBox_0"
                controls
                muted
                width="394px"
                height="400px"
            ></video>

            <LivePlayer
                :videoUrl="customUrlLiver"
                live
                :autoplay="false"
                style="width: 394px; height: 500px"
                @timeupdate="timeupdate"
                :poster="poster"
                ref="LivePlayer"
            />

            <video
                id="vidoeBox_1"
                controls
                muted
                width="394px"
                height="400px"
            ></video>
        </div>
    </div>
</template>
<script>
import flvjs from "flv.js";
import LivePlayer from "@liveqing/liveplayer";
import { TcPlayer } from "tcplayer";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {
        LivePlayer,
        TcPlayer,
    },
    name: "",
    data() {
        return {
            poster: require("@image/bigScreen/icon/sxtPoster.gif"),
            customUrl: "http://192.168.23.235:53372/live/ch12_0_1.flv",
            customUrlLiver: "",
            listList: [
                {
                    name: "flv",
                    startTime: "",
                    endTime: "",
                    longTime: "",
                },
                {
                    name: "liveplayer",
                    startTime: "",
                    endTime: "",
                    longTime: "",
                },
                {
                    name: "tcplayer",
                    startTime: "",
                    endTime: "",
                    longTime: "",
                },
            ],
            type: "flv",
            typeList: [
                {
                    name: "flv",
                    url: "http://192.168.23.235:53372/live/ch12_0_1.flv",
                },
                {
                    name: "m3u8",
                    url: "http://192.168.23.235:80/live/ch2_0_1.m3u8",
                },
            ],
            flvPlayer: null,
            tcplayer: null,
            num: 0,
        };
    },
    props: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        ...mapActions([""]),
        playVideo() {
            let startTime = this.common.getTimeYmdhms();
            this.listList[0].startTime = startTime;
            this.listList[1].startTime = startTime;
            this.listList[2].startTime = startTime;
            this.customUrlLiver = this.customUrl;
            this.num = 0;
            if (this.type == "flv") {
                if (this.flvPlayer) {
                    this.clearflvPlayer();
                } else {
                    this.actFlvPlayer();
                }
            } else {
                this.tcplayer = new TcPlayer(`vidoeBox_2`, {
                    m3u8: this.customUrl,
                    autoplay: true,
                    live: true,
                    width: "400",
                    height: "300",
                    flash: false,
                    muted: true,
                    poster: this.poster,
                });
                this.tcplayer.play();
                this.getLongTime(startTime, 2);
            }
        },
        timeupdate(msg) {
            if (!this.num) {
                this.num++;
                this.getLongTime(this.listList[1].startTime, 1);
            }
        },
        getLongTime(startTime, index) {
            let endTime = this.common.getTimeYmdhms();
            let longTime =
                new Date(endTime).getTime() - new Date(startTime).getTime();
            this.listList[index].endTime = endTime;
            this.listList[index].longTime = longTime;
        },
        changeAudio(item) {
            this.type = item.name;
            this.customUrl = item.url;
        },
        actFlvPlayer() {
            this.flvPlayer = flvjs.createPlayer({
                type: "flv",
                isLive: true,
                hasAudio: false,
                url: this.customUrl,
            });
            let dom = document.getElementById(`vidoeBox_0`);
            this.flvPlayer.attachMediaElement(dom);
            this.flvPlayer.load();
            this.flvPlayer.play();
            this.flvPlayer.on(flvjs.Events.MEDIA_INFO, (res) => {
                this.getLongTime(startTime, 0);
                // console.log("媒体信息-加载完成", res, );
            });
        },
        clearflvPlayer() {
            this.flvPlayer.pause();
            this.flvPlayer.unload();
            this.flvPlayer.detachMediaElement();
            this.flvPlayer.destroy();
            this.flvPlayer = null;
            this.actFlvPlayer();
        },
    },
    destroyed() {
        if (this.type == "flv") {
            this.flvPlayer.pause();
            this.flvPlayer.unload();
            this.flvPlayer.detachMediaElement();
            this.flvPlayer.destroy();
            this.flvPlayer = null;
        } else {
            this.tcplayer.destroy();
        }
    },
};
</script>
<style scoped lang="scss">
.container-ctn {
    width: 100%;
    .custom-part {
        width: 100%;
        margin-top: 20px;
    }
    .audio-part {
        width: 100%;
        margin-top: 20px;
        .audio-item {
            width: 100px;
            height: 80px;
            border: 1px solid #000;
            position: relative;
            cursor: pointer;
        }
    }
    .type-part {
        width: 100%;
        margin-top: 20px;
        .type-item {
            width: 500px;
            border: 1px solid #000;
            position: relative;
        }
    }
}
</style>